import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdvertGoalModel } from '@/models/Adverts';
import { TikTokAdsLocationModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokSearchLocationOptions = ({
  advertiserId,
  goal,
  search,
}: {
  advertiserId?: string;
  goal?: AdvertGoalModel;
  search?: string;
}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdsLocationModel[]> | null>(
    () => {
      if (!accountId || !advertiserId || !goal || !search) return null;

      return `tiktok-search-locations-data-${accountId}-${advertiserId}-${goal.value}-${search}`;
    },
    async () => {
      if (!accountId || !advertiserId || !goal || !search) return null;

      return await TikTokAPI.searchTikTokLocations({
        accountId,
        advertiserId,
        goal: goal.value,
        search,
      });
    }
  );

  return {
    searchLocationOptions: data?.data ?? [],
    searchLocationOptionsIsLoading: isLoading,
    searchLocationOptionsError: error,
    refetchSearchLocationOptions: mutate,
  };
};

export default useTikTokSearchLocationOptions;
