import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdvertGoalModel } from '@/models/Adverts';
import { TikTokAdsLocationModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokLocationRecommendations = ({
  advertiserId,
  goal,
}: {
  advertiserId?: string;
  goal?: AdvertGoalModel;
}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdsLocationModel[]> | null>(
    () => {
      if (!accountId || !advertiserId || !goal) return null;

      return `tiktok-location-recommendations-data-${accountId}-${advertiserId}-${goal.value}`;
    },
    async () => {
      if (!accountId || !advertiserId || !goal) return null;

      return await TikTokAPI.getTikTokLocationRecommendations({
        accountId,
        advertiserId,
        goal: goal.value,
      });
    }
  );

  return {
    locationRecommendations: data?.data,
    locationRecommendationsIsLoading: isLoading,
    locationRecommendationsError: error,
    refetchLocationRecommendations: mutate,
  };
};

export default useTikTokLocationRecommendations;
