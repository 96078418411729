import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import MetricsAPI from '@/network/MetricsAPI';

const useTrackIdFromSpotifyId = ({ spotifyId }: { spotifyId?: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<{
    chartmetric_ids: number[];
  }> | null>(
    () => {
      if (!spotifyId) return null;

      return `track-id-from-spotify-id-data-${spotifyId}`;
    },
    async () => {
      if (!spotifyId) return null;

      return await MetricsAPI.getTrackIdFromSpotifyId({ spotifyId });
    }
  );

  return {
    chartmetricIds: data?.data.chartmetric_ids,
    trackIdFromSpotifyIdIsLoading: isLoading,
    trackIdFromSpotifyIdError: error,
    refetchTrackIdFromSpotifyId: mutate,
  };
};

export default useTrackIdFromSpotifyId;
