import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistPitchAccountItemsModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccountContext from '../context/useAccountContext';

const usePlaylistPitch = ({ playlistPitchId }: { playlistPitchId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistPitchAccountItemsModel> | null>(
    () => {
      if (!accountId || !playlistPitchId) return null;

      return `playlist-pitch-data-${accountId}-${playlistPitchId}`;
    },
    async () => {
      if (!accountId || !playlistPitchId) return null;

      return await PlaylistAPI.getPlaylistPitch({ accountId, playlistPitchId });
    }
  );

  return {
    playlistPitch: data?.data,
    playlistPitchIsLoading: isLoading,
    playlistPitchError: error,
    refetchPlaylistPitch: mutate,
  };
};

export default usePlaylistPitch;
