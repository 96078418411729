import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AccountModel } from '@/models/Account';
import AccountAPI from '@/network/AccountAPI';

const useAccount = ({ firebaseAccountId }: { firebaseAccountId?: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AccountModel> | null>(
    () => {
      if (!firebaseAccountId) return null;

      return `account-data-${firebaseAccountId}`;
    },
    async () => {
      if (!firebaseAccountId) return null;

      return await AccountAPI.getAccount({ firebaseAccountId });
    }
  );

  return {
    account: data?.data,
    accountIsLoading: isLoading,
    accountError: error,
    refetchAccount: mutate,
  };
};

export default useAccount;
