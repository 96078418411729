import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';

import useArtist from '../artist/useArtist';

const useValidateUniqueArtist = ({ bypassCheck }: { bypassCheck?: boolean }) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<boolean> | null>(
    () => {
      if (!artist || bypassCheck) return null;

      return `validate-unique-artist-data-${artist.id}`;
    },
    async () => {
      if (!artist || bypassCheck) return null;

      return await WebsiteBuilderAPI.validateUniqueArtist({ artistId: artist.id });
    }
  );

  return {
    isValidUniqueArtist: data?.data,
    validateUniqueArtistIsLoading: isLoading,
    validateUniqueArtistError: error,
    refetchValidateUniqueArtist: mutate,
  };
};

export default useValidateUniqueArtist;
