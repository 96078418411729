import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { IdNameType } from '@/models/Generic';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokSearchInterestOptions = ({ advertiserId, search }: { advertiserId?: string; search?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<IdNameType[]> | null>(
    () => {
      if (!accountId || !advertiserId || !search) return null;

      return `tiktok-search-interests-data-${accountId}-${advertiserId}-${search}`;
    },
    async () => {
      if (!accountId || !advertiserId || !search) return null;

      return await TikTokAPI.searchTikTokInterests({
        accountId,
        advertiserId,
        search,
      });
    }
  );

  return {
    searchInterestOptions: data?.data ?? [],
    searchInterestOptionsIsLoading: isLoading,
    searchInterestOptionsError: error,
    refetchSearchInterestOptions: mutate,
  };
};

export default useTikTokSearchInterestOptions;
