import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifySearchDataItemsModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

import useArtist from '../artist/useArtist';

const useSpotifyArtistTopTracks = () => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifySearchDataItemsModel[]> | null>(
    () => {
      if (!artist || !artist.platformIds.spotifyId) return null;

      return `spotify-artist-top-tracks-data-${artist.platformIds.spotifyId}`;
    },
    async () => {
      if (!artist || !artist.platformIds.spotifyId) return null;

      return await SpotifyAPI.getArtistTopTracks({ spotifyId: artist.platformIds.spotifyId });
    }
  );

  return {
    spotifyArtistTopTracks: data?.data,
    spotifyArtistTopTracksIsLoading: isLoading,
    spotifyArtistTopTracksError: error,
    refetchSpotifyArtistTopTracks: mutate,
  };
};

export default useSpotifyArtistTopTracks;
