import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdReportModel } from '@/models/Adverts';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaAdReport = ({ campaignId }: { campaignId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AdReportModel> | null>(
    () => {
      if (!accountId || !campaignId) return null;

      return `meta-ad-report-data-${accountId}-${campaignId}`;
    },
    async () => {
      if (!accountId || !campaignId) return null;

      return await MetaAPI.getMetaAdReport({ accountId, campaignId });
    }
  );

  return {
    metaAdReport: data?.data.data[0],
    metaAdReportIsLoading: isLoading,
    metaAdReportError: error,
    refetchMetaAdReport: mutate,
  };
};

export default useMetaAdReport;
