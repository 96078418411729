import { useMemo } from 'react';

import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdsPotentialReachModel } from '@/models/Adverts';
import { IdName, IdNameType } from '@/models/Generic';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokPotentialReach = ({
  goal,
  platforms,
  advertiserId,
  identityId,
  budget,
  startTime,
  endTime,
  gender,
  ageGroups,
  interests,
  locations,
}: {
  goal?: string;
  platforms?: string[];
  advertiserId?: string;
  identityId?: string;
  budget?: number;
  startTime?: string;
  endTime?: string;
  gender?: string;
  ageGroups?: string[];
  interests?: IdNameType[];
  locations?: IdName[];
}) => {
  const { accountId } = useAccountContext();

  const isValid = useMemo(
    () =>
      goal !== undefined &&
      platforms !== undefined &&
      platforms.length > 0 &&
      advertiserId !== '' &&
      identityId !== '' &&
      budget !== undefined &&
      startTime !== undefined &&
      endTime !== undefined &&
      gender !== undefined &&
      ageGroups !== undefined &&
      ageGroups.length > 0 &&
      interests !== undefined &&
      interests.length > 0 &&
      locations !== undefined &&
      locations.length > 0,
    [goal, platforms, advertiserId, identityId, budget, startTime, endTime, gender, ageGroups, interests, locations]
  );

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AdsPotentialReachModel> | null>(
    () => {
      if (
        !accountId ||
        !goal ||
        !platforms ||
        !advertiserId ||
        !identityId ||
        !budget ||
        !startTime ||
        !endTime ||
        !gender ||
        !ageGroups ||
        !interests ||
        !locations ||
        !isValid
      )
        return null;

      return `tiktok-potential-reach-data-${accountId}-${advertiserId}-${identityId}-${budget}-${startTime}-${endTime}-${goal}-${gender}-${ageGroups.toString()}-${interests.toString()}-${locations.toString()}`;
    },
    async () => {
      if (
        !accountId ||
        !goal ||
        !platforms ||
        !advertiserId ||
        !identityId ||
        !budget ||
        !startTime ||
        !endTime ||
        !gender ||
        !ageGroups ||
        !interests ||
        !locations
      )
        return null;

      return await TikTokAPI.getTikTokPotentialReach({
        accountId,
        ad: {
          goal,
          platforms,
          budget,
          startTime: new Date(startTime).toISOString(),
          endTime: new Date(endTime).toISOString(),
          targeting: {
            gender,
            ageGroups,
            interests,
            locations,
          },
        },
        adAccount: {
          advertiserId,
          identityId,
        },
      });
    }
  );

  return {
    potentialReach: data?.data,
    potentialReachIsLoading: isLoading,
    potentialReachError: error,
    refetchPotentialReach: mutate,
  };
};

export default useTikTokPotentialReach;
