import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistPreviousPitchesAPIModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccountContext from '../context/useAccountContext';

const usePreviousPlaylistPitches = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistPreviousPitchesAPIModel> | null>(
    () => {
      if (!accountId) return null;

      return `previous-playlist-pitches-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await PlaylistAPI.getPreviousPlaylistPitches({
        accountId,
      });
    }
  );

  return {
    previousPlaylistPitches: data?.data,
    previousPlaylistPitchesIsLoading: isLoading,
    previousPlaylistPitchesError: error,
    refetchPreviousPlaylistPitches: mutate,
  };
};

export default usePreviousPlaylistPitches;
