import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaCreativeModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaCreative = ({ advertId }: { advertId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaCreativeModel> | null>(
    () => {
      if (!accountId || !advertId) return null;

      return `meta-creative-data-${accountId}-${advertId}`;
    },
    async () => {
      if (!accountId || !advertId) return null;

      return await MetaAPI.getMetaCreative({ accountId, advertId });
    }
  );

  return {
    metaCreative: data?.data,
    metaCreativeIsLoading: isLoading,
    metaCreativeError: error,
    refetchMetaCreative: mutate,
  };
};

export default useMetaCreative;
