import { useMemo } from 'react';

import useSWRImmutable from 'swr/immutable';

import {
  ProductConfig,
  SimplePriceModel,
  SubDiscountPriceModel,
  SubscriptionPriceModel,
} from '@/constants/ProductConfig';
import useSubscription from '@/hooks/account/useSubscription';
import { PriceResponse, SubscriptionDataModel } from '@/models/Payments';
import PaymentAPI from '@/network/PaymentAPI';

const usePricing = () => {
  const priceIds = [
    ProductConfig.playlistPitch.priceId,
    ProductConfig.playlistPitch.subscribedPriceId,
    ProductConfig.metaAds.priceId,
    ProductConfig.tikTokAds.priceId,
    ProductConfig.subscription.priceId.monthly,
    ProductConfig.subscription.priceId.yearly,
  ];

  const { isSubscribed } = useSubscription();
  function getUserPrice(subDiscountPriceModel: SubDiscountPriceModel | null | undefined): SimplePriceModel {
    if (!subDiscountPriceModel) return { price: 0, priceId: '' };
    return isSubscribed ? subDiscountPriceModel.discount : subDiscountPriceModel.standard;
  }

  const { data, isLoading, error } = useSWRImmutable<PriceResponse[]>('usePricing', async () => {
    const response = await PaymentAPI.getPriceList(priceIds);
    return response.data;
  });

  const { subscriptionData, playlistPitchFull, metaAdsFull, tikTokAdsFull, subscription } = useMemo(() => {
    if (!data) return {};

    const priceDictionary = data.reduce(
      (dictionary, price) => {
        dictionary[price.id as string] = price.unitAmount / 100.0 || 0;
        return dictionary;
      },
      {} as { [key: string]: number }
    );

    const playlistPitchFull = {
      standard: {
        priceId: ProductConfig.playlistPitch.priceId,
        price: priceDictionary[ProductConfig.playlistPitch.priceId] || 0,
      } as SimplePriceModel,
      discount: {
        priceId: ProductConfig.playlistPitch.subscribedPriceId,
        price: priceDictionary[ProductConfig.playlistPitch.subscribedPriceId] || 0,
      } as SimplePriceModel,
    } as SubDiscountPriceModel;

    const metaAdsFull = {
      standard: {
        priceId: ProductConfig.metaAds.priceId,
        price: priceDictionary[ProductConfig.metaAds.priceId] || 0,
      } as SimplePriceModel,
      discount: {
        priceId: ProductConfig.metaAds.subscribedPriceId,
        price: priceDictionary[ProductConfig.metaAds.subscribedPriceId] || 0,
      } as SimplePriceModel,
    } as SubDiscountPriceModel;

    const tikTokAdsFull = {
      standard: {
        priceId: ProductConfig.tikTokAds.priceId,
        price: priceDictionary[ProductConfig.tikTokAds.priceId] || 0,
      } as SimplePriceModel,
      discount: {
        priceId: ProductConfig.tikTokAds.subscribedPriceId,
        price: priceDictionary[ProductConfig.tikTokAds.subscribedPriceId] || 0,
      } as SimplePriceModel,
    } as SubDiscountPriceModel;

    const subscription = {
      monthly: {
        priceId: ProductConfig.subscription.priceId.monthly,
        price: priceDictionary[ProductConfig.subscription.priceId.monthly] || 0,
      } as SimplePriceModel,
      yearly: {
        priceId: ProductConfig.subscription.priceId.yearly,
        price: priceDictionary[ProductConfig.subscription.priceId.yearly] || 0,
      } as SimplePriceModel,
    } as SubscriptionPriceModel;

    const subscriptionData = [
      {
        period: 'yearly',
        price: subscription.yearly.price,
        pricePerMonth: subscription.yearly.price / 12.0,
        stripePriceId: subscription.yearly.priceId,
        applePriceId: '',
      },
      {
        period: 'monthly',
        price: subscription.monthly.price,
        pricePerMonth: subscription.monthly.price,
        stripePriceId: subscription.monthly.priceId,
        applePriceId: '',
      },
    ] as SubscriptionDataModel[];

    return {
      subscriptionData: subscriptionData,
      playlistPitchFull: playlistPitchFull,
      metaAdsFull: metaAdsFull,
      tikTokAdsFull: tikTokAdsFull,
      subscription: subscription,
    };
  }, [data]);

  return {
    subscriptionData: subscriptionData || null,

    playlistPitch: playlistPitchFull ? getUserPrice(playlistPitchFull) : null,
    playlistPitchFull: playlistPitchFull || null,

    metaAds: metaAdsFull ? getUserPrice(metaAdsFull) : null,
    metaAdsFull: metaAdsFull || null,

    tikTokAds: tikTokAdsFull ? getUserPrice(tikTokAdsFull) : null,
    tikTokAdsFull: tikTokAdsFull || null,

    subscription: subscription || null,

    isDataLoading: isLoading != undefined ? isLoading : true,
    pricingError: error || null,
  };
};

export default usePricing;
