import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsLocationSearchModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaSearchLocationOptions = ({ search }: { search?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdsLocationSearchModel> | null>(
    () => {
      if (!accountId || !search) return null;

      return `meta-search-locations-data-${accountId}-${search}`;
    },
    async () => {
      if (!accountId || !search) return null;

      return await MetaAPI.searchMetaLocations({
        accountId,
        search,
      });
    }
  );

  return {
    searchLocationOptions: data?.data.data ?? [],
    searchLocationOptionsIsLoading: isLoading,
    searchLocationOptionsError: error,
    refetchSearchLocationOptions: mutate,
  };
};

export default useMetaSearchLocationOptions;
