import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { InsightsModel } from '@/models/Insights';
import InsightsAPI from '@/network/InsightsAPI';

import useSubscription from '../account/useSubscription';
import useArtist from '../artist/useArtist';

const useBenchmarkInsights = ({ platform, type }: { platform: string; type: string }) => {
  const { artist } = useArtist();
  const { isSubscribed } = useSubscription();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<InsightsModel> | null>(
    () => {
      if (!artist || !platform || !type || !isSubscribed) return null;

      return `benchmark-insights-data-${artist.id}-${platform}-${type}`;
    },
    async () => {
      if (!artist || !platform || !type || !isSubscribed) return null;

      return await InsightsAPI.getBenchmarkInsight({
        platform,
        type,
        artistId: artist.id,
      });
    }
  );

  return {
    benchmarkInsights: data?.data,
    genre: data?.data.genreGroupDisplayName,
    absoluteValue: data?.data.absoluteValue ?? 0,
    percentage: data?.data.displayBenchmarkPercentile ?? 0,
    lowValue: Number(data?.data.minBenchmarkValue.toFixed(0)) ?? 0,
    lowValueString: data?.data.minBenchmarkValue ? Math.floor(data?.data.minBenchmarkValue).toLocaleString() : '0',
    highValue: Number(data?.data.displayMaxBenchmarkValue.toFixed(0)),
    highValueString: data?.data.displayMaxBenchmarkValue
      ? Math.floor(data?.data.displayMaxBenchmarkValue).toLocaleString()
      : '0',
    benchmarkInsightsIsLoading: isLoading,
    benchmarkInsightsError: error,
    refetchBenchmarkInsights: mutate,
  };
};

export default useBenchmarkInsights;
