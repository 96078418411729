import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ActivityModel } from '@/models/Activity';
import ActivityAPI from '@/network/ActivityAPI';

import useAccountContext from '../context/useAccountContext';

const useAccountActivity = ({ pageSize = 50 }: { pageSize?: number } = {}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ActivityModel> | null>(
    () => {
      if (!accountId) return null;

      return `account-activity-data-${accountId}-${pageSize}`;
    },
    async () => {
      if (!accountId) return null;

      return await ActivityAPI.getAccountActivity({ accountId, pageSize: 200 });
    }
  );

  return {
    accountActivity: data?.data.items,
    accountActivityIsLoading: isLoading,
    accountActivityError: error,
    refetchAccountActivity: mutate,
  };
};

export default useAccountActivity;
