import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifyArtistSearchAPIModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

const useSpotifyArtistSearch = ({ search }: { search: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifyArtistSearchAPIModel> | null>(
    () => {
      if (!search) return null;

      return `spotify-artist-search-data-${search}`;
    },
    async () => {
      if (!search) return null;

      return await SpotifyAPI.searchArtist({ query: search });
    }
  );

  return {
    spotifyArtistSearchResult: data?.data.artists.items,
    spotifyArtistSearchResultIsLoading: isLoading,
    spotifyArtistSearchResultError: error,
    refetchSpotifyArtistSearchResult: mutate,
  };
};

export default useSpotifyArtistSearch;
