import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { YoutubeMarketCoverageMetrics } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const useYoutubeViewLocationsMetrics = () => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<YoutubeMarketCoverageMetrics> | null>(
    () => {
      if (!artist) return null;

      return `youtube-view-locations-metrics-data-${artist.id}`;
    },
    async () => {
      if (!artist || !artist.platformIds.chartmetricId || artist.platformIds.chartmetricId === 0) return null;

      return await MetricsAPI.getYoutubeViewsLocations({ chartmetricId: artist.platformIds.chartmetricId });
    }
  );

  return {
    youtubeViewLocationsMetrics: data?.data,
    youtubeViewLocationsMetricsIsLoading: isLoading,
    youtubeViewLocationsMetricsError: error,
    refetchYoutubeViewLocationsMetrics: mutate,
  };
};

export default useYoutubeViewLocationsMetrics;
