import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TodoAccountDataModel } from '@/models/Todo';
import ToDoAPI from '@/network/ToDoAPI';

import useAccountContext from '../context/useAccountContext';

const usePriorityTodos = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TodoAccountDataModel[]> | null>(
    () => {
      if (!accountId) return null;

      return `todo-priority-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await ToDoAPI.getPriorityToDos({ accountId });
    }
  );

  return {
    priorityTodos: data?.data.slice(0, 2),
    priorityTodosIsLoading: isLoading,
    priorityTodosError: error,
    refetchPriorityTodos: mutate,
  };
};

export default usePriorityTodos;
