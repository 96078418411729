import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { InstagramAudienceHistoryMetricsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const useInstagramAudienceHistoricMetrics = ({ fromDate }: { fromDate?: string }) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<{
    metrics: InstagramAudienceHistoryMetricsModel[];
  }> | null>(
    () => {
      if (!artist || !fromDate) return null;

      return `instagram-audience-historic-metrics-data-${artist.id}-${fromDate}`;
    },
    async () => {
      if (!artist || !fromDate || !artist.platformIds.chartmetricId || artist.platformIds.chartmetricId === 0)
        return null;

      return await MetricsAPI.getInstagramAudienceHistoricMetrics({
        chartmetricId: artist.platformIds.chartmetricId,
        fromDate,
      });
    }
  );

  return {
    instagramAudienceHistoricMetrics: data?.data,
    instagramAudienceHistoricMetricsIsLoading: isLoading,
    instagramAudienceHistoricMetricsError: error,
    refetchInstagramAudienceHistoricMetrics: mutate,
  };
};

export default useInstagramAudienceHistoricMetrics;
