import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsInterestsModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaRecommendedInterests = ({ adAccountId }: { adAccountId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdsInterestsModel[]> | null>(
    () => {
      if (!accountId || !adAccountId) return null;

      return `meta-recommended-interests-data-${accountId}-${adAccountId}`;
    },
    async () => {
      if (!accountId || !adAccountId) return null;

      return await MetaAPI.getMetaInterestRecommendations({
        accountId,
        adAccountId,
      });
    }
  );

  return {
    recommendedInterests: data?.data,
    recommendedInterestsIsLoading: isLoading,
    recommendedInterestsError: error,
    refetchRecommendedInterests: mutate,
  };
};

export default useMetaRecommendedInterests;
