import { useMemo } from 'react';

import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdsPotentialReachAPIModel } from '@/models/Adverts';
import { MetaTargetingAudiencesModel, MetaTergetingGeolocationDataModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaPotentialReach = ({
  platforms,
  adAccountId,
  genders,
  ageMin,
  ageMax,
  audiences,
  countryGroups,
  countries,
  cities,
  regions,
}: {
  platforms?: string[];
  adAccountId?: string;
  genders?: number[];
  ageMin?: number;
  ageMax?: number;
  audiences?: MetaTargetingAudiencesModel[];
  countryGroups?: string[];
  countries?: string[];
  cities?: MetaTergetingGeolocationDataModel[];
  regions?: MetaTergetingGeolocationDataModel[];
}) => {
  const { accountId } = useAccountContext();

  const isValid = useMemo(
    () =>
      platforms !== undefined &&
      platforms.length > 0 &&
      adAccountId !== '' &&
      genders !== undefined &&
      ageMin !== undefined &&
      ageMax !== undefined &&
      audiences !== undefined &&
      countryGroups !== undefined &&
      countries !== undefined &&
      cities !== undefined &&
      regions !== undefined,
    [platforms, adAccountId, genders, ageMin, ageMax, audiences, countryGroups, countries, cities, regions]
  );

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AdsPotentialReachAPIModel> | null>(
    () => {
      if (
        !accountId ||
        !platforms ||
        !adAccountId ||
        !genders ||
        !ageMin ||
        !ageMax ||
        !audiences ||
        !countryGroups ||
        !countries ||
        !cities ||
        !regions ||
        !isValid
      )
        return null;

      return `meta-potential-reach-data-${accountId}-${accountId}-${genders.toString()}-${audiences.map((audience) => audience.name).toString()}-${countryGroups.toString()}-${countries.toString()}-${cities.map((city) => city.name).toString()}-${regions.map((region) => region.name).toString()}-${ageMin}=${ageMax}`;
    },
    async () => {
      if (
        !accountId ||
        !platforms ||
        !adAccountId ||
        !genders ||
        !ageMin ||
        !ageMax ||
        !audiences ||
        !countryGroups ||
        !countries ||
        !cities ||
        !regions
      )
        return null;

      return await MetaAPI.getPotentialReachEstimate({
        accountId,
        adAccountId,
        platforms,
        targeting: {
          genders,
          ageMin,
          ageMax,
          audiences,
          geoLocations: {
            countryGroups,
            countries,
            cities,
            regions,
          },
        },
      });
    }
  );

  return {
    potentialReach: data?.data.data,
    potentialReachIsLoading: isLoading,
    potentialReachError: error,
    refetchPotentialReach: mutate,
  };
};

export default useMetaPotentialReach;
