import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsAccountModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaAdAccounts = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdsAccountModel> | null>(
    () => {
      if (!accountId) return null;

      return `meta-ad-account-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await MetaAPI.getMetaAdAccounts({ accountId });
    }
  );

  return {
    metaAdAccounts: data?.data,
    metaAdAccountsIsLoading: isLoading,
    metaAdAccountsError: error,
    refetchMetaAdAccounts: mutate,
  };
};

export default useMetaAdAccounts;
