import { useMemo } from 'react';

import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { IdNameType } from '@/models/Generic';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokSuggestedInterests = ({
  advertiserId,
  interests,
}: {
  advertiserId?: string;
  interests?: IdNameType[];
}) => {
  const { accountId } = useAccountContext();

  const interestsString = useMemo(() => {
    if (!interests) return undefined;

    return interests.map((value) => value.name.replace('#', '')).toString();
  }, [interests]);

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<IdNameType[]> | null>(
    () => {
      if (!accountId || !advertiserId || !interestsString) return null;

      return `tiktok-suggested-interests-data-${accountId}-${advertiserId}-${interestsString}`;
    },
    async () => {
      if (!accountId || !advertiserId || !interestsString) return null;

      return await TikTokAPI.getTikTokInterestSuggestions({
        accountId,
        advertiserId,
        selectedKeywords: interestsString,
      });
    }
  );

  return {
    suggestedInterests: data?.data,
    suggestedInterestsIsLoading: isLoading,
    suggestedInterestsError: error,
    refetchSuggestedInterests: mutate,
  };
};

export default useTikTokSuggestedInterests;
