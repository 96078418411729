import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsPagesModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaPages = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdsPagesModel> | null>(
    () => {
      if (!accountId) return null;

      return `meta-pages-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await MetaAPI.getMetaPages({ accountId });
    }
  );

  return {
    metaPages: data?.data,
    metaPagesIsLoading: isLoading,
    metaPagesError: error,
    refetchMetaPages: mutate,
  };
};

export default useMetaPages;
