import { useContext } from 'react';

import { AccountContext } from '@/contexts/AccountContext';

const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccountContext must be used within a AccountProvider');
  }
  return context;
};

export default useAccountContext;
