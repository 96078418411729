import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifyAudienceLocationsMetricsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const useSpotifyAudienceLocationMetrics = () => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } =
    useSWRImmutable<AxiosResponse<SpotifyAudienceLocationsMetricsModel> | null>(
      () => {
        if (!artist) return null;

        return `spotify-audience-location-metrics-data-${artist.id}`;
      },
      async () => {
        if (!artist || !artist.platformIds.chartmetricId || artist.platformIds.chartmetricId === 0) return null;

        return await MetricsAPI.getSpotifyMonthlyListenerLocations({
          chartmetricId: artist.platformIds.chartmetricId,
          limit: 50,
        });
      }
    );

  return {
    spotifyAudienceLocationMetrics: data?.data,
    spotifyAudienceLocationMetricsIsLoading: isLoading,
    spotifyAudienceLocationMetricsError: error,
    refetchSpotifyAudienceLocationMetrics: mutate,
  };
};

export default useSpotifyAudienceLocationMetrics;
