import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifyMusicModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

import useAccountContext from '../context/useAccountContext';

const useSpotifyArtistSingles = ({ spotifyId }: { spotifyId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifyMusicModel[]> | null>(
    () => {
      if (!accountId || !spotifyId) return null;

      return `artist-singles-data-${accountId}-${spotifyId}`;
    },
    async () => {
      if (!accountId || !spotifyId) return null;

      return await SpotifyAPI.getArtistSingles({ spotifyId, limit: 50 });
    }
  );

  return {
    artistSingles: data?.data,
    artistSinglesIsLoading: isLoading,
    artistSinglesError: error,
    refetchArtistSingles: mutate,
  };
};

export default useSpotifyArtistSingles;
