import { useMemo } from 'react';

import UserTracking from '@/services/UserTracking';

import useAccountContext from './context/useAccountContext';

const useUserTracking = () => {
  const { account } = useAccountContext();

  const userTracking = useMemo(() => {
    if (!account) return;
    return new UserTracking({ account });
  }, [account]);

  return userTracking;
};

export default useUserTracking;
