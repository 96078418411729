import { addDays } from 'date-fns';

import useAccountContext from '../context/useAccountContext';

const useSubscription = () => {
  const { account } = useAccountContext();

  return {
    isSubscribed: !!account?.subscription?.isActive,
    isMonthly: !!(account?.subscription?.billingType === 'monthly'),
    isFreeTrial: !!(account?.subscription?.status === 'trial'),
    isWeb: !!(account?.subscription?.platform?.name === 'stripe'),
    expiresOn: account?.subscription?.endDate || addDays(new Date(), 7),
    status: account?.subscription?.status || '',
  };
};

export default useSubscription;
