import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokIdentityModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokIdentity = ({ advertiserId }: { advertiserId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokIdentityModel> | null>(
    () => {
      if (!accountId || !advertiserId) return null;

      return `tiktok-identity-data-${accountId}-${advertiserId}`;
    },
    async () => {
      if (!accountId || !advertiserId) return null;

      return await TikTokAPI.getTikTokIdentityList({
        accountId,
        advertiserId,
      });
    }
  );

  return {
    tiktokIdentity: data?.data,
    tiktokIdentityIsLoading: isLoading,
    tiktokIdentityError: error,
    refetchTiktokIdentity: mutate,
  };
};

export default useTikTokIdentity;
