import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokAdsInterestModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokRecommendedInterests = ({ advertiserId }: { advertiserId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdsInterestModel[]> | null>(
    () => {
      if (!accountId || !advertiserId) return null;

      return `tiktok-recommended-interests-data-${accountId}-${advertiserId}`;
    },
    async () => {
      if (!accountId || !advertiserId) return null;

      return await TikTokAPI.getTikTokInterestRecommendations({
        accountId,
        advertiserId,
      });
    }
  );

  return {
    recommendedInterests: data?.data,
    recommendedInterestsIsLoading: isLoading,
    recommendedInterestsError: error,
    refetchRecommendedInterests: mutate,
  };
};

export default useTikTokRecommendedInterests;
