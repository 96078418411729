import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetricsChartModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

const usePlaylistTrackStats = ({
  chartmetricId,
  fromDate,
  toDate,
}: {
  chartmetricId?: number;
  fromDate?: string;
  toDate?: string;
}) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetricsChartModel> | null>(
    () => {
      if (!chartmetricId || !fromDate || !toDate) return null;

      return `playlist-track-stats-data-${chartmetricId}-${fromDate}-${toDate}`;
    },
    async () => {
      if (!chartmetricId || !fromDate || !toDate) return null;

      return await MetricsAPI.getPlaylistTrackStatsThroughTime({
        chartmetricId,
        fromDate,
        toDate,
      });
    }
  );

  return {
    playlistTrackStats: data?.data,
    playlistTrackStatsIsLoading: isLoading,
    playlistTrackStatsError: error,
    refetchPlaylistTrackStats: mutate,
  };
};

export default usePlaylistTrackStats;
