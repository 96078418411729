import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokTargetingSuggestionsModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useArtist from '../artist/useArtist';
import useAccountContext from '../context/useAccountContext';

const useTikTokTargetingSuggestions = ({ advertiserId, goal }: { advertiserId?: string; goal?: string }) => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokTargetingSuggestionsModel> | null>(
    () => {
      if (!accountId || !advertiserId) return null;

      return `tiktok-targeting-suggestions-data-${accountId}-${advertiserId}-${artist?.id}`;
    },
    async () => {
      if (!accountId || !advertiserId) return null;

      return await TikTokAPI.getTikTokTargetingSuggestions({
        accountId,
        advertiserId,
        goal,
      });
    }
  );

  return {
    targetingSuggestions: data?.data,
    targetingSuggestionsIsLoading: isLoading,
    targetingSuggestionsError: error,
    refetchTargetingSuggestions: mutate,
  };
};

export default useTikTokTargetingSuggestions;
