import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdVideoModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaAdVideos = ({ adAccountId }: { adAccountId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdVideoModel> | null>(
    () => {
      if (!accountId || !adAccountId) return null;

      return `meta-ad-videos-data-${accountId}-${adAccountId}`;
    },
    async () => {
      if (!accountId || !adAccountId) return null;

      return await MetaAPI.getMetaAdVideos({ accountId, adAccountId });
    }
  );

  return {
    metaAdVideos: data?.data.data,
    metaAdVideosIsLoading: isLoading,
    metaAdVideosError: error,
    refetchMetaAdVideos: mutate,
  };
};

export default useMetaAdVideos;
