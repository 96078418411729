import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MeetTheCuratorModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

const useMeetTheCurator = ({ curatorSpotifyId }: { curatorSpotifyId?: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MeetTheCuratorModel> | null>(
    () => {
      if (!curatorSpotifyId) return null;

      return `meet-the-curator-data-${curatorSpotifyId}`;
    },
    async () => {
      if (!curatorSpotifyId) return null;

      return await PlaylistAPI.getMeetTheCurator({ curatorSpotifyId });
    }
  );

  return {
    curatorDetails: data?.data,
    curatorDetailsIsLoading: isLoading,
    curatorDetailsError: error,
    refetchCuratorDetails: mutate,
  };
};

export default useMeetTheCurator;
