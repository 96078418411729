import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetricsPlatformArtistModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const usePlatformArtistMetrics = ({
  platform,
  fromDate,
  toDate,
}: {
  platform?: string;
  fromDate?: string;
  toDate?: string;
}) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetricsPlatformArtistModel> | null>(
    () => {
      if (!artist || !platform || !fromDate || !toDate) return null;

      return `platform-artist-metrics-data-${artist.id}-${platform}-${fromDate}-${toDate}`;
    },
    async () => {
      if (
        !artist ||
        !platform ||
        !fromDate ||
        !toDate ||
        !artist.platformIds.chartmetricId ||
        artist.platformIds.chartmetricId === 0
      )
        return null;

      return await MetricsAPI.getPlatformArtistMetrics({
        chartmetricId: artist.platformIds.chartmetricId,
        platform,
        fromDate,
        toDate,
      });
    }
  );

  return {
    platformArtistMetrics: data?.data,
    platformArtistMetricsIsLoading: isLoading,
    platformArtistMetricsError: error,
    refetchPlatformArtistMetrics: mutate,
  };
};

export default usePlatformArtistMetrics;
