import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokAdsUserVideosModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokUserVideos = ({ advertiserId, identityId }: { advertiserId?: string; identityId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdsUserVideosModel> | null>(
    () => {
      if (!accountId || !advertiserId || !identityId) return null;

      return `tiktok-location-recommendations-data-${accountId}-${advertiserId}-${identityId}`;
    },
    async () => {
      if (!accountId || !advertiserId || !identityId) return null;

      return await TikTokAPI.getTikTokUserVideos({
        accountId,
        advertiserId,
        identityId,
      });
    }
  );

  return {
    userVideos: data?.data,
    userVideosIsLoading: isLoading,
    userVideosError: error,
    refetchUserVideos: mutate,
  };
};

export default useTikTokUserVideos;
