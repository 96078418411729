import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';

const useValidateUrlRoute = ({ route }: { route: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<boolean> | null>(
    () => {
      if (!route) return null;

      return `validate-url-route-data-${route}`;
    },
    async () => {
      if (!route) return null;

      return await WebsiteBuilderAPI.checkURLValid({ route });
    }
  );

  return {
    isValidUrlRoute: data?.data,
    validateUrlRouteIsLoading: isLoading,
    validateUrlRouteError: error,
    refetchValidateUrlRoute: mutate,
  };
};

export default useValidateUrlRoute;
