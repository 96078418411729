import useSWRImmutable from 'swr/immutable';

import { MetaAdVideoDataThumbnailsDataModel, MetaCreativeModel } from '@/models/Meta';
import FacebookAPI from '@/network/FacebookAPI';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaCreativeBulk = ({ advertIds }: { advertIds?: string[] }) => {
  const { accountId, account } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<{ advertId: string; data?: MetaCreativeModel }[] | null>(
    () => {
      if (!accountId || !advertIds) return null;

      return `meta-creative-data-bulk-${accountId}-${advertIds.join('-')}`;
    },
    async () => {
      if (!accountId || !advertIds) return null;

      return await Promise.all(
        advertIds.map(async (advertId) => {
          try {
            const response = await MetaAPI.getMetaCreative({ accountId, advertId });
            if (
              response.data.video_id &&
              account?.accessTokens?.filter((item) => item.platform === 'meta')[0].accessToken
            ) {
              const videoResponse = await FacebookAPI.getVideoDetailsById({
                videoId: response.data.video_id,
                accessToken: account?.accessTokens?.filter((item) => item.platform === 'meta')[0].accessToken,
              });
              return {
                advertId,
                data: {
                  ...response.data,
                  video_url: videoResponse.data.thumbnails.data.filter(
                    (item: MetaAdVideoDataThumbnailsDataModel) => item.is_preferred
                  )[0].uri,
                },
              };
            } else {
              return { advertId, data: response.data };
            }
          } catch {
            return { advertId };
          }
        })
      );
    }
  );

  return {
    metaCreativeBulk: data,
    metaCreativeBulkIsLoading: isLoading,
    metaCreativeBulkError: error,
    refetchMetaCreativeBulk: mutate,
  };
};

export default useMetaCreativeBulk;
