import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifySearchModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

import useAccountContext from '../context/useAccountContext';

const useSpotifySearchArtistTracks = ({ artistName, search }: { artistName?: string; search?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifySearchModel> | null>(
    () => {
      if (!accountId || !artistName || !search) return null;

      return `spotify-search-artist-tracks-data-${accountId}-${artistName}-${search}`;
    },
    async () => {
      if (!accountId || !artistName || !search) return null;

      return await SpotifyAPI.searchArtistTrack({
        artistName,
        search,
      });
    }
  );

  return {
    searchArtistTracksOptions: data?.data.tracks.items ?? [],
    searchArtistTracksOptionsIsLoading: isLoading,
    searchArtistTracksOptionsError: error,
    refetchSearchArtistTracksOptions: mutate,
  };
};

export default useSpotifySearchArtistTracks;
