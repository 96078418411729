import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokAdvertiserIdModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokAdvertisingId = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdvertiserIdModel> | null>(
    () => {
      if (!accountId) return null;

      return `tiktok-advertising-id-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await TikTokAPI.getTikTokAdvertisingId({ accountId });
    }
  );

  return {
    tiktokAdvertisingId: data?.data,
    tiktokAdvertisingIdIsLoading: isLoading,
    tiktokAdvertisingIdError: error,
    refetchTiktokAdvertisingId: mutate,
  };
};

export default useTikTokAdvertisingId;
