import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistPitchAccountModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccountContext from '../context/useAccountContext';

const useAccountPlaylistPitch = ({ continuationToken }: { continuationToken?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistPitchAccountModel> | null>(
    () => {
      if (!accountId) return null;

      return `playlist-pitch-data-${accountId}${continuationToken ? `-${continuationToken}` : ''}`;
    },
    async () => {
      if (!accountId) return null;

      return await PlaylistAPI.getAccountPlaylistPitches({ accountId, continuationToken });
    }
  );

  return {
    accountPlaylistPitch: data?.data,
    accountPlaylistPitchIsLoading: isLoading,
    accountPlaylistPitchError: error,
    refetchAccountPlaylistPitch: mutate,
  };
};

export default useAccountPlaylistPitch;
