import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TodoAccountModel } from '@/models/Todo';
import ToDoAPI from '@/network/ToDoAPI';

import useAccountContext from '../context/useAccountContext';

const useAccountTodos = ({ status, pageSize = 10 }: { status?: string; pageSize?: number }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TodoAccountModel> | null>(
    () => {
      if (!accountId || !status) return null;

      return `todo-account-data-${accountId}-${status}-${pageSize}`;
    },
    async () => {
      if (!accountId || !status) return null;

      return await ToDoAPI.getAccountToDos({ accountId, status, pageSize });
    }
  );

  return {
    accountTodos: data?.data,
    partnerTodos: data?.data.filter((item) => item.category === 'partners'),
    adsTodos: data?.data.filter((item) => item.category === 'ads'),
    promotionsTodos: data?.data.filter((item) => item.category === 'promotion'),
    artistServicesTodos: data?.data.filter((item) => item.category === 'artist-services'),
    templateTodos: data?.data.filter((item) => item.category === 'templates'),
    tipsTodos: data?.data.filter((item) => item.category === 'tips'),
    accountTodosIsLoading: isLoading,
    accountTodosError: error,
    refetchAccountTodos: mutate,
  };
};

export default useAccountTodos;
