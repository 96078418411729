import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetricsPlatformArtistModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const useMultiplePlatformArtistMetrics = ({
  platforms,
  fromDate,
  toDate,
}: {
  platforms?: string[];
  fromDate?: string;
  toDate?: string;
}) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetricsPlatformArtistModel>[] | null>(
    () => {
      if (!artist || !platforms || !fromDate || !toDate) return null;

      return `platform-artist-metrics-data-${artist.id}-${platforms.join('-')}-${fromDate}-${toDate}`;
    },
    async () => {
      if (
        !artist ||
        !platforms ||
        !fromDate ||
        !toDate ||
        !artist.platformIds.chartmetricId ||
        artist.platformIds.chartmetricId === 0
      )
        return null;

      return await Promise.all(
        platforms.map(async (platform) => {
          return await MetricsAPI.getPlatformArtistMetrics({
            chartmetricId: artist.platformIds.chartmetricId,
            platform,
            fromDate,
            toDate,
          });
        })
      );
    }
  );

  return {
    multiPlatformArtistMetrics: data?.map((response) => response?.data),
    multiPlatformArtistMetricsIsLoading: isLoading,
    multiPlatformArtistMetricsError: error,
    refetchMultiPlatformArtistMetrics: mutate,
  };
};

export default useMultiplePlatformArtistMetrics;
