import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdvertModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaAd = ({ advertId }: { advertId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdvertModel> | null>(
    () => {
      if (!accountId || !advertId) return null;

      return `meta-ad-data-${accountId}-${advertId}`;
    },
    async () => {
      if (!accountId || !advertId) return null;

      return await MetaAPI.getMetaAdvert({ accountId, advertId });
    }
  );

  return {
    metaAd: data?.data,
    metaAdIsLoading: isLoading,
    metaAdError: error,
    refetchMetaAd: mutate,
  };
};

export default useMetaAd;
