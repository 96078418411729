import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokAdsVideoModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokAdVideo = ({
  advertiserId,
  identityId,
  tikTokVideoId,
}: {
  advertiserId?: string;
  identityId?: string;
  tikTokVideoId?: string;
}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdsVideoModel> | null>(
    () => {
      if (!accountId || !advertiserId || !identityId || !tikTokVideoId) return null;

      return `tiktok-ad-data-${accountId}-${advertiserId}-${identityId}-${tikTokVideoId}`;
    },
    async () => {
      if (!accountId || !advertiserId || !identityId || !tikTokVideoId) return null;

      return await TikTokAPI.getTikTokAdVideo({
        accountId,
        advertiserId,
        identityId,
        tikTokVideoId,
      });
    }
  );

  return {
    tikTokAdVideo: data?.data,
    tikTokAdVideoIsLoading: isLoading,
    tikTokAdVideoError: error,
    refetchTikTokAdVideo: mutate,
  };
};

export default useTikTokAdVideo;
