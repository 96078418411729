import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlatformOverviewMetricsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const usePlatformOverviewMetrics = ({ type }: { type: 'social' | 'music' }) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlatformOverviewMetricsModel> | null>(
    () => {
      if (!artist || !type) return null;

      return `platform-overview-metrics-data-${artist.id}-${type}`;
    },
    async () => {
      if (!artist || !type || !artist.platformIds.chartmetricId || artist.platformIds.chartmetricId === 0) return null;

      return await MetricsAPI.getOverviewMetrics({
        chartmetricId: artist.platformIds.chartmetricId,
        type: type,
      });
    }
  );

  return {
    platformOverviewMetrics: data?.data,
    platformOverviewMetricsIsLoading: isLoading,
    platformOverviewMetricsError: error,
    refetchPlatformOverviewMetrics: mutate,
  };
};

export default usePlatformOverviewMetrics;
