import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsLanguagesSearchModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaSearchLanguageOptions = ({ search }: { search?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaAdsLanguagesSearchModel> | null>(
    () => {
      if (!accountId || !search) return null;

      return `meta-search-languages-data-${accountId}-${search}`;
    },
    async () => {
      if (!accountId || !search) return null;

      return await MetaAPI.searchMetaLanguages({
        accountId,
        search,
      });
    }
  );

  return {
    searchLanguageOptions: data?.data.data ?? [],
    searchLanguageOptionsIsLoading: isLoading,
    searchLanguageOptionsError: error,
    refetchSearchLanguageOptions: mutate,
  };
};

export default useMetaSearchLanguageOptions;
