import { DependencyList, useEffect, useRef } from 'react';

export const useDebounce = (callback: () => void, delay: number, deps: DependencyList) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callback, delay, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

/*
example: TikTokAudienceTargeting.tsx
*/
