import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { TikTokAdvertModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokAd = ({ advertId }: { advertId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<TikTokAdvertModel> | null>(
    () => {
      if (!accountId || !advertId) return null;

      return `tiktok-ad-data-${accountId}-${advertId}`;
    },
    async () => {
      if (!accountId || !advertId) return null;

      return await TikTokAPI.getTikTokAdvert({ accountId, advertId });
    }
  );

  return {
    tikTokAd: data?.data,
    tikTokAdIsLoading: isLoading,
    tikTokAdError: error,
    refetchTikTokAd: mutate,
  };
};

export default useTikTokAd;
