import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { YoutubeAudienceMetricsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const useYoutubeAudienceMetrics = () => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<YoutubeAudienceMetricsModel> | null>(
    () => {
      if (!artist) return null;

      return `youtube-audience-metrics-data-${artist.id}`;
    },
    async () => {
      if (!artist) return null;

      return await MetricsAPI.getStaticYoutubeAudienceMetrics({
        chartmetricId: artist?.platformIds.chartmetricId,
      });
    }
  );

  return {
    youtubeAudienceMetrics: data?.data,
    youtubeAudienceMetricsIsLoading: isLoading,
    youtubeAudienceMetricsError: error,
    refetchYoutubeAudienceMetrics: mutate,
  };
};

export default useYoutubeAudienceMetrics;
