import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PlaylistRecommendationsModel } from '@/models/Playlist';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccountContext from '../context/useAccountContext';

const usePlaylistRecommendations = ({ playlistRecommendationsId }: { playlistRecommendationsId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PlaylistRecommendationsModel> | null>(
    () => {
      if (!accountId || !playlistRecommendationsId) return null;

      return `playlist-recommendations-data-${accountId}-${playlistRecommendationsId}`;
    },
    async () => {
      if (!accountId || !playlistRecommendationsId) return null;

      return await PlaylistAPI.getPlaylistRecommendations({
        playlistRecommendationsId,
      });
    }
  );

  return {
    playlistRecommendations: data?.data,
    playlistRecommendationsIsLoading: isLoading,
    playlistRecommendationsError: error,
    refetchPlaylistRecommendations: mutate,
  };
};

export default usePlaylistRecommendations;
