import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';

const useWebsiteMailingList = ({ mailingListId }: { mailingListId?: string }) => {
  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<Blob> | null>(
    () => {
      if (!mailingListId) return null;

      return `website-mailing-list-data-${mailingListId}`;
    },
    async () => {
      if (!mailingListId) return null;

      return await WebsiteBuilderAPI.exportMailingList({ mailingListId });
    }
  );

  return {
    websiteMailingList: data?.data,
    websiteMailingListIsLoading: isLoading,
    websiteMailingListError: error,
    refetchWebsite: mutate,
  };
};

export default useWebsiteMailingList;
