import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetricsPlaylistsModel } from '@/models/Metrics';
import MetricsAPI from '@/network/MetricsAPI';

import useArtist from '../artist/useArtist';

const usePlaylistMetrics = ({ platform }: { platform?: string }) => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetricsPlaylistsModel[]> | null>(
    () => {
      if (!artist || !platform) return null;

      return `playlist-metrics-data-${artist.id}-${platform}`;
    },
    async () => {
      if (!artist || !platform || !artist.platformIds.chartmetricId || artist.platformIds.chartmetricId === 0)
        return null;

      return await MetricsAPI.getPlaylists({
        chartmetricId: artist.platformIds.chartmetricId,
        platform,
      });
    }
  );

  return {
    playlistMetrics: data?.data,
    playlistMetricsIsLoading: isLoading,
    playlistMetricsError: error,
    refetchPlaylistMetrics: mutate,
  };
};

export default usePlaylistMetrics;
