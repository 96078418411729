import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AdReportDataModel } from '@/models/Adverts';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokAdReport = ({ campaignId }: { campaignId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AdReportDataModel> | null>(
    () => {
      if (!accountId || !campaignId) return null;

      return `tiktok-ad-report-data-${accountId}-${campaignId}`;
    },
    async () => {
      if (!accountId || !campaignId) return null;

      return await TikTokAPI.getTikTokAdvertMetrics({ accountId, campaignId });
    }
  );

  return {
    tikTokAdReport: data?.data,
    tikTokAdReportIsLoading: isLoading,
    tikTokAdReportError: error,
    refetchTikTokAdReport: mutate,
  };
};

export default useTikTokAdReport;
