import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifyMusicModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

import useAccountContext from '../context/useAccountContext';

const useSpotifyArtistAlbums = ({ spotifyId }: { spotifyId?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifyMusicModel[]> | null>(
    () => {
      if (!accountId || !spotifyId) return null;

      return `artist-albums-data-${accountId}-${spotifyId}`;
    },
    async () => {
      if (!accountId || !spotifyId) return null;

      return await SpotifyAPI.getArtistAlbums({ spotifyId, limit: 50 });
    }
  );

  return {
    artistAlbums: data?.data,
    artistAlbumsIsLoading: isLoading,
    artistAlbumsError: error,
    refetchArtistAlbums: mutate,
  };
};

export default useSpotifyArtistAlbums;
