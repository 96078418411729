import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PagesAdvertsModel } from '@/models/Adverts';
import AdvertAPI from '@/network/AdvertAPI';

import useAccountContext from '../context/useAccountContext';

const usePagedAdverts = ({
  status = 'active',
  sortedBy = 'newest',
  offset = 0,
  limit = 50,
}: {
  status?: string;
  sortedBy?: string;
  offset?: number;
  limit?: number;
} = {}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PagesAdvertsModel> | null>(
    () => {
      if (!accountId) return null;

      return `pages-adverts-data-${accountId}-${status}-${sortedBy}-${offset}-${limit}`;
    },
    async () => {
      if (!accountId) return null;

      return await AdvertAPI.getPagesAdverts({ accountId, status, sortedBy, offset, limit });
    }
  );

  return {
    pagedAdverts: data?.data.items,
    pagedAdvertsTotalCount: data?.data.itemCount,
    pagedAdvertsIsLoading: isLoading,
    pagedAdvertsError: error,
    refetchPagedAdverts: mutate,
  };
};

export default usePagedAdverts;
