import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaAdsInterestsModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useAccountContext from '../context/useAccountContext';

const useMetaSearchInterestOptions = ({ adAccountId, search }: { adAccountId?: string; search?: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<{ data: MetaAdsInterestsModel[] }> | null>(
    () => {
      if (!accountId || !adAccountId || !search) return null;

      return `tiktok-search-interests-data-${accountId}-${adAccountId}-${search}`;
    },
    async () => {
      if (!accountId || !adAccountId || !search) return null;

      return await MetaAPI.searchMetaTargetAudiences({
        accountId,
        adAccountId,
        search,
      });
    }
  );

  return {
    searchInterestOptions: data?.data.data ?? [],
    searchInterestOptionsIsLoading: isLoading,
    searchInterestOptionsError: error,
    refetchSearchInterestOptions: mutate,
  };
};

export default useMetaSearchInterestOptions;
