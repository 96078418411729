import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { SpotifyArtistModel } from '@/models/Spotify';
import SpotifyAPI from '@/network/SpotifyAPI';

import useArtist from '../artist/useArtist';

const useSpotifySimilarArtists = () => {
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<SpotifyArtistModel[]> | null>(
    () => {
      if (!artist || !artist.platformIds.spotifyId) return null;

      return `spotify-similar-artists-data-${artist.platformIds.spotifyId}`;
    },
    async () => {
      if (!artist || !artist.platformIds.spotifyId) return null;

      return await SpotifyAPI.getArtistSimilarArtists({
        spotifyId: artist.platformIds.spotifyId,
      });
    }
  );

  return {
    spotifySimilarArtists: data?.data,
    spotifySimilarArtistsIsLoading: isLoading,
    spotifySimilarArtistsError: error,
    refetchSpotifySimilarArtists: mutate,
  };
};

export default useSpotifySimilarArtists;
