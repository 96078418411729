import useSWRImmutable from 'swr/immutable';

import { PagesAdvertItemModel } from '@/models/Adverts';
import { TikTokAdsVideoModel } from '@/models/TikTok';
import TikTokAPI from '@/network/TikTokAPI';

import useAccountContext from '../context/useAccountContext';

const useTikTokAdVideoBulk = ({ ads }: { ads?: PagesAdvertItemModel[] }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<{ advertId: string; data?: TikTokAdsVideoModel }[] | null>(
    () => {
      if (!accountId || !ads) return null;

      return `tiktok-ad-data-bulk-${accountId}-${ads.map((ad) => ad.id).join('-')}`;
    },
    async () => {
      if (!accountId || !ads) return null;

      return await Promise.all(
        ads.map(async (ad) => {
          try {
            const response = await TikTokAPI.getTikTokAdVideo({
              accountId,
              advertiserId: ad.metadata.advertiserId,
              identityId: ad.metadata.identityId,
              tikTokVideoId: ad.metadata.tikTokItemId,
            });
            return { advertId: ad.id, data: response.data };
          } catch {
            return { advertId: ad.id };
          }
        })
      );
    }
  );

  return {
    tikTokAdVideoBulk: data,
    tikTokAdVideoBulkIsLoading: isLoading,
    tikTokAdVideoBulkError: error,
    refetchTikTokAdVideoBulk: mutate,
  };
};

export default useTikTokAdVideoBulk;
