import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { MetaTargetingSuggestionsModel } from '@/models/Meta';
import MetaAPI from '@/network/MetaAPI';

import useArtist from '../artist/useArtist';
import useAccountContext from '../context/useAccountContext';

const useMetaTargetingSuggestions = () => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<MetaTargetingSuggestionsModel> | null>(
    () => {
      if (!accountId) return null;

      return `meta-targeting-suggestions-data-${accountId}-${artist?.id}`;
    },
    async () => {
      if (!accountId) return null;

      return await MetaAPI.getMetaTargetingSuggestions({
        accountId,
      });
    }
  );

  return {
    targetingSuggestions: data?.data,
    targetingSuggestionsIsLoading: isLoading,
    targetingSuggestionsError: error,
    refetchTargetingSuggestions: mutate,
  };
};

export default useMetaTargetingSuggestions;
