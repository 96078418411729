import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ActiveCampaignsModel } from '@/models/Campaigns';
import AdvertAPI from '@/network/AdvertAPI';

import useAccountContext from '../context/useAccountContext';

const useActiveCampaigns = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ActiveCampaignsModel> | null>(
    accountId ? `active-campaign-data-${accountId}` : null,
    async () => (accountId ? await AdvertAPI.getActiveCampaigns({ accountId }) : null)
  );

  return {
    activeCampaigns: data?.data,
    activeCampaignsIsLoading: isLoading,
    activeCampaignsError: error,
    refetchActiveCampaigns: mutate,
  };
};

export default useActiveCampaigns;
